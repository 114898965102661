import React from 'react'
import Layout from '../components/Layout'
import * as styles from '../styles/cost.module.css'

export default function Cost() {
    return (
        <Layout>
            <section className={styles.cost}>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        Costs and procedures
                    </h2>
                </div>
                <div className="row">
                <div className="col-1-of-2">
                    <h3 className="heading-tertiary u-margin-bottom-small">
                        No hidden costs and no surprises
                    </h3>
                    <h3 className="heading-tertiary u-margin-bottom-small">
                        This is our guarantee to you
                    </h3>
                    <h3 className="heading-tertiary u-margin-bottom-small">
                        We do not charge for taking credit/debit card payments
                    </h3>
                    <p className="paragraph">
                        One Apostille costs £65+ VAT.
                    </p>
                    <p className="paragraph">
                        If you have more documents to do, any other subsequent documents are charged at £55+VAT.
                    </p>
                    <p className="paragraph">
                        If you wish to apply for Apostille yourself but you need your documents notarised we charge £30+VAT for the first document and £15+ VAT thereafter.
                    </p>

                </div>
                <div className="col-1-of-2">
                    <h3 className="heading-tertiary u-margin-bottom-small">
                        * IMPORTANT
                    </h3>
                    <p className="paragraph">
                        If you wish to benefit for our multiple document rate, you need to place the order for all your documents at once. If you order one document on one occasion and another document later on we shall charge the  standard non- discounted fee.
                    </p>
                    <p className="paragraph">
                        If you need to have your documents translated, the standard fee for most languages is £0.09/word (min £30).
                    </p>
                    <p className="paragraph">
                        If you need to have your documents certified we charge £15+VAT for the first document and £10+VAT thereafter.
                    </p>
                    <p className="paragraph">
                        We post the certified documents via first class and Apostille and notarised documents via first class signed for service. This is included in the fee. If you need it posted via another courier or quicker, we shall apply the charge of the courier.
                    </p>
                </div>
                </div>
            </section>
        </Layout>
    )
}